import * as React from "react"
import { Link, navigate } from "gatsby"
import { contactURL } from "@lib/urls"
import { Container } from "react-bootstrap"
import Layout from "@components/Layout/Layout"
import Seo from "../components/seo"

const NotFoundPage = () => {
  React.useEffect(()=> {
    navigate("/404/")
  })
  return (
    <Layout
      pageData={{
        page_class: "notfound-page-wrap",
        layout: "notfound",
      }}
    >
      <Container className="notfound-page">
        <h1>Oops! Page Not Found.</h1>
        <p className="description">
          The page you are looking for cannot be found. Please check the URL or
          try using our search bar to find what you&apos;re looking for.
        </p>
        <div className="cta-section">
          <Link to="/" className="button button-orange">
            Go to Home
          </Link>
          <Link to={contactURL} className="button button-gray">
            Contact Us
          </Link>
        </div>
      </Container>
    </Layout>
  )
}

export const Head = () => <Seo customCanonical="https://www.providentestate.com/404/" title="Page Not Found" />

export default NotFoundPage
